(function () {
	'use strict';
	angular
		.module('app')
		.factory('manageNotifications', [
			'$sce',
			'$translate',
			'seedcodeCalendar',
			'daybackIO',
			'utilities',
			'environment',
			'hash',
			manageNotifications,
		]);

	function manageNotifications(
		$sce,
		$translate,
		seedcodeCalendar,
		daybackIO,
		utilities,
		environment,
		hash
	) {
		return {
			getRemoteNotifications: getRemoteNotifications,
			setNotificationsRead: setNotificationsRead,
			getNotificationsRead: getNotificationsRead,
			expiresNotification: expiresNotification,
		};

		function getRemoteNotifications(moreCount, callback) {
			var url;
			var fileName = 'notifications';
			var fileExtension = 'js';

			if (moreCount) {
				fileName += '-' + moreCount;
			}

			fileName += '.' + fileExtension;

			url = `https://seedcode.github.io/dayback-notifications/${fileName}`;

			// Set a date object to use for cachebusting. Set the time to midnight so it only changes once a day
			let now = new Date();
			now.setHours(0, 0, 0, 0);

			// load jsonp content
			crypt.jsonPRequest(
				`${url}?cachebust=${now.getTime()}`,
				'notifications',
				processNotifications
			);

			function processNotifications(result) {
				var now = moment(moment().format('YYYY-MM-DD'));
				var notifications = {};
				var notification;

				if (!result) {
					//Exit if we get an error or nothing comes back
					return;
				}
				notifications.show = true;
				notifications.data = [];
				notifications.dateUpdated = now;

				//Clean notification data and trust content as html
				for (var i = 0; i < result.notifications.length; i++) {
					notification = result.notifications[i];
					if (
						platformMatch(notification.platforms) &&
						environmentMatch(notification.environments)
					) {
						notification.content = $sce.trustAsHtml(
							notification.content
						);
						notifications.data.push(notification);
					}
				}

				callback(notifications);
			}
		}

		function getNotificationsRead(callback) {
			daybackIO.getNotificationUserData('notifications', callback, null);
		}

		function setNotificationsRead(notifications, lastReadID) {
			if (!notifications || !notifications.length) {
				return;
			}
			var data = {};
			data.lastReadID = lastReadID;
			data.lastReadDate = moment().valueOf();
			daybackIO.setNotificationUserData(
				'',
				'notifications',
				data,
				true,
				lastReadUpdated,
				null
			);
			function lastReadUpdated() {
				console.log('updated');
			}
		}

		function expiresNotification(compareDate) {
			var config = seedcodeCalendar.get('config');
			var hashData = hash.get();
			var now = compareDate
				? compareDate
				: moment(moment().format('YYYY-MM-DD'));
			var expires = moment(hashData.expiresDate);
			var daysRemaining = hash.getExpirationDays(); //expires.diff(now, 'days');
			var dayPlural = $translate.instant(['day', 'days']);
			//Don't go into negative numbers for days remaining string
			var dayString =
				Math.abs(daysRemaining) === 1
					? daysRemaining + ' ' + dayPlural.day
					: Math.abs(daysRemaining) + ' ' + dayPlural.days;

			var result = {};
			result.type = hashData.type;
			result.orderNumber = hashData.orderNumber;
			result.expiresDate = expires;
			result.dayString = dayString;
			result.dateUpdated = now;
			result.daysRemaining = daysRemaining;
			result.isExpired = daysRemaining < 0;

			//Branch for different Notifications
			//Trial expiring
			if (result.type === 'trial') {
				result.notificationType = 'trial';
				result.show = !fbk.isSalesforce();
				result.expiresAlert = result.show && daysRemaining < 6;
			}
			//In-app updates expiring
			else if (result.type === 'order') {
				result.notificationType = 'inAppExpires';
				result.show =
					!fbk.isSalesforce() && config.admin && daysRemaining < 46;
				result.expiresAlert =
					result.show && daysRemaining < 15 && daysRemaining > -1;
			}

			return result;
		}

		function platformMatch(platforms) {
			if (!platforms || !platforms.length) {
				return true;
			}

			var currentPlatform = utilities.getDBKPlatform().toUpperCase();

			for (var i = 0; i < platforms.length; i++) {
				if (
					platforms[i] &&
					platforms[i].toUpperCase() === currentPlatform
				) {
					return true;
				}
			}

			return false;
		}

		function environmentMatch(environments) {
			var matchCheck;
			if (!environments || !environments.length) {
				return true;
			}

			for (var i = 0; i < environments.length; i++) {
				matchCheck = environment[environments[i]];
				if (matchCheck) {
					return true;
				}
			}

			return false;
		}
	}
})();
